.redeemed-header-image {
    position: absolute;
    left: 10%;
    top: 4%;
    height: 6rem;
    cursor: pointer;
  }
  
.redeemed-logout-btn {
    /* position: absolute; */
    /* right: 10px; */
    /* top: 4%; */
    /* width: 12.1rem;
    height: 3.9rem;
    padding: 10px 20px; */
    border: 1px solid #14a905;
    background-color: transparent;
    color: #fff;
    border-radius: 77px;
}

  
  

/* .universal-logo {
    position: absolute;
    bottom: 3.5%;
    left: 50%;
    transform: translate(-50%);
    width: 121px; 
    height: auto;
    z-index: -100;
} */
