html {
  overflow-x: hidden;
}

body {
  background: linear-gradient(180deg, #000 0.51%, #000 63.39%);
  /* margin: 0% 12%; */
  padding: 0;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

p,
h1,
h2,
h3,
h4,
h5 {
  color: #ffffff;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, proxima-nova, sans-serif !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #000;
}

.header-image {
  height: 6rem;
  padding-left: 3rem;
}

.button-group {
  display: flex;
  /* gap: 1rem; */
}

.redeem-btn {
  /* width: 12.1rem;
    height: 3.9rem; */
  /* padding: 10px 20px; */
  border: 1px solid #14a905;
  background-color: #14a905;
  color: #fff;
  border-radius: 77px;
}

.redeem-btn-fixed {
  background-color: #14a905;
  color: #fff;
  border-radius: 300px;
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 999;
  width: 80px;
  height: 80px;
}

.signin-btn {
  /* width: 12.1rem;
    height: 3.9rem; */
  /* padding: 10px 20px; */
  border: 1px solid #14a905;
  background-color: transparent;
  color: #fff;
  border-radius: 77px;
}

.banner-container {
  display: flex;
  justify-content: center;
}

.wicked-banner {
  border-radius: 30px;
  width: 100%;
  /* max-width: 97%; */
  max-height: 40rem;
}

.gradient-section {
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.63%, rgba(0, 0, 0, 0.73) 9.65%, #000 37.47%);
  color: white;
  /* padding: 40px 5%; */
  /* margin-top: -100px; */
  z-index: 10;
}

.section-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.phone-container {
  position: relative;
  width: 1400px;
  height: 1300px;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  /* margin-left: 6%; */
  /* align-items: flex-end;  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #ddd; */
}

.overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  top: 58%;
  color: white;
  text-align: center;
  padding: 0.8rem 0;
}

.wizard-card {
  position: absolute;
  bottom: 33rem;
  left: 35rem;
  /* rotate: 12deg; */
  width: 20%;
  height: auto;
  z-index: -6;
}

.nessarose-card {
  position: absolute;
  bottom: 33rem;
  left: 35rem;
  /* rotate: -12deg; */
  width: 20%;
  height: auto;
  z-index: -5;
}

.elphaba-card {
  position: absolute;
  width: 20%;
  height: auto;
  /* rotate: -7.126deg; */
  left: 35rem;
  bottom: 33rem;
  z-index: -1;
}

.madame-card {
  position: absolute;
  width: 20%;
  height: auto;
  /* rotate: 10.887deg; */
  bottom: 33rem;
  right: 35rem;
  z-index: -4;
}

.glinda-card {
  position: absolute;
  width: 20%;
  height: auto;
  /* rotate: 7.126deg; */
  left: 35rem;
  bottom: 33rem;
  z-index: -2;
}

.fiyero-card {
  position: absolute;
  width: 20%;
  height: auto;
  /* rotate: -12.887deg; */
  bottom: 34rem;
  left: 35rem;
  z-index: -3;
}

.faq-section {
  display: block;
  /* margin-top: 10%;
    margin-bottom: 15%; */
  /* margin-left: 18%; */
  /* width: 56rem; */
  height: auto;
  max-width: 1024px;
  margin: 78px auto 50px auto;
  background-color: #242424;
  border-radius: 24px;
  opacity: 0.98;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 20px 50px 60px 50px;
  /* gap: 1.5rem; */
  flex-shrink: 0;
  box-shadow: 0px 4px 8.9px 0px rgba(0, 0, 0, 0.00);
  flex-wrap: wrap;
}

.faq-section-sm {
  padding: 20px 30px 50px 30px !important;
}

.faq-section p {
  /* text-align: left; */
  /* font-family: 'Century Gothic Regular'; */
  font-size: 32;
  color: '#fff';
  /* margin-bottom: 20px; */
}

.questions-container {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  width: 100%;
  height: auto;
  /* border-bottom: 0.01rem solid #454545; */
  margin-bottom: 50px;
  /* border: 1px solid #ddd; */
}

.button-group2 {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.redeem-btn2 {
  width: 14.1rem;
  height: 3.9rem;
  padding: 10px 20px;
  margin-bottom: 3rem;
  border: 1px solid #14a905;
  background-color: #14a905;
  color: #fff;
  cursor: pointer;
  border-radius: 77px;
  z-index: 10;
}

/* .learn-btn {
    width: 14.1rem;
    height: 3.9rem;
    padding: 10px 20px;
    border: 1px solid #14a905;
    background-color: #000;
    color: #fff;
    border-radius: 77px;
    z-index: 10;
} */

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #ddd; */
}

.universal-logo2 {
  display: block;
  margin: 0 auto;
  height: 150px;
}

/* Testing */
.lightning {
  position: absolute;
  z-index: -1;
  top: 40%;
  left: 43%;
  width: 200px;
  /* Smaller size */
  height: 200px;
  /* Smaller size */
  background: radial-gradient(circle, rgba(0, 255, 0, 0.7) 20%, rgba(0, 255, 0, 0) 80%);
  opacity: 0;
  animation: glowFlash 3s infinite;
  /* box-shadow: 0 0 50px 20px rgba(0, 255, 0, 0.5); */
}

@keyframes glowFlash {

  0%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 0.5;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0;
  }
}

.glow-image {
  width: 300px;
  /* Adjust the size of the image */
  height: auto;
  border-radius: 10px;
  /* Optional: rounded corners */
  box-shadow: 0 0 20px 10px rgba(0, 255, 0, 0.6);
  /* Glow effect */
}

.glow-wrapper {
  position: relative;
  display: inline-block;
}

.glow-wrapper::before {
  content: '';
  position: absolute;
  top: 72%;
  left: calc(50% - 8px);
  width: 36%;
  height: 36%;
  background: rgb(0 128 255 / 92%);
  border-radius: 2%;
  transform: translate(-50%, -50%) scale(1.2);
  filter: blur(20px);
  z-index: -1;
  animation: pulseGlow 3s infinite;
}

.glow-wrapper img {
  width: 300px;
  /* Adjust the size of the image */
  height: auto;
  display: block;
  border-radius: 10px;
  /* Optional: rounded corners for the image */
}

@keyframes pulseGlow {

  0%,
  100% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.6;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 1;
  }
}

.blur-edge {
  background-image: url('https://stage.moviebonus.com/static/media/wicked_banner.f64d7dc6915fcf4b1749.jpg');
  width: 100%;
  height: 300px;
  /* you need to match the shadow color to your background or image border for the desired effect*/
  box-shadow: 0 0 8px 8px rgb(0, 0, 0) inset;
}